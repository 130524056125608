import * as React from "react";

import { TARGETS, WidgetOptions } from "./";
import { getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";

import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { isEqual } from "lodash";
import withEditModal from "../../../components/frontend-editing/WithEditModal";

interface SpacerProps extends PageWidgetBaseProps<WidgetOptions> {
    className: string;
    spaceControl: string;
    context: CMSProviderProperties;
    isFrontEndEditable?: boolean;
}

interface SpacerState {
    disableWidget: boolean;
    options: WidgetOptions;
    className: string;
    spaceControl: string;
}

export class SpacerBase extends React.PureComponent<SpacerProps, SpacerState> {
    constructor(props: SpacerProps) {
        super(props);
        this.state = {
            disableWidget: true,
            options: props.options,
            spaceControl: props.spaceControl,
            className: props.className,
        };
    }

    public componentDidMount() {
        this.setState({ disableWidget: !isClientLoggedIn() });
    }

    public componentDidUpdate(prevProps: Readonly<SpacerProps>): void {
        if (!isEqual(this.props.options, prevProps.options)) {
            const { options } = this.props;
            const spaceControl = `${options.spacing ? options.spacing : ""}`;
            const className = findMultiSelectStyleClassNames(this.props.context.theme, TARGETS, options.styleIds);
            this.setState({ className, spaceControl, options });
            if (this.props.isFrontEndEditable) {
                this.setState({ className: `${className} show-controls` });
            }
        }
    }

    public render(): JSX.Element | null {
        const { spaceControl, className, options } = this.state;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        return (
            <div
                className={`spacer-styled ${className} ${options.useSpecificUnits ? "" : spaceControl} ${hideWidget}`}
                style={{
                    paddingTop: `${options.useSpecificUnits ? options.spacingAmount : 0}px`,
                    marginLeft: `${options.spacingToLeft || 0}px`,
                    marginRight: `${options.spacingToRight || 0}px`,
                }}
            />
        );
    }
}
export const SpacerWithModal = withEditModal(SpacerBase);

const mapStateToProps = (state: State) => ({
    isFrontEndEditable: state.frontendPageEditState.isFrontEndEditable,
});

export const Spacer = connect(mapStateToProps)(SpacerWithModal);
