import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { PageWidgetSpec, Widget, WidgetSpec, WidgetType } from "../../";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Spacer } from "./Spacer";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds: any[];
    spacing: ContentSpacing;
    useSpecificUnits: boolean;
    spacingAmount: number;
    spacingToLeft: number;
    spacingToRight: number;
}

type ContentSpacing = "dk-pt-s" | "dk-pt-m" | "dk-pt-l" | "dk-pt-xxl";

export const TARGETS = ["spacer", "spacer-model"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "spacer-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetSpacer, "spacerWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetSpacer, "spacerWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetSpacer, "general"),
                    properties: [
                        [
                            {
                                variable: "spacing",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "spacing"),
                                type: "select",
                                default: "dk-pt-s",
                                visible: (options: WidgetOptions) => !options.useSpecificUnits,
                                optionList: [
                                    {
                                        value: "dk-pt-s",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "sSpacing"),
                                    },
                                    {
                                        value: "dk-pt-m",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "mSpacing"),
                                    },
                                    {
                                        value: "dk-pt-l",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "lSpacing"),
                                    },
                                    {
                                        value: "dk-pt-xxl",
                                        label: getI18nLocaleObject(namespaceList.widgetWebContent, "xlSpacing"),
                                    },
                                ] as any,
                            },
                            // The new feature for selecting specific units will be resolved inside: MCMS-3529
                            {
                                variable: "useSpecificUnits",
                                label: getI18nLocaleObject(namespaceList.admin, "useSpecificUnits"),
                                type: "checkbox",
                                groupName: "spacingGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.widgetSpacer, "spacingGroupTitle"),
                            },
                            {
                                variable: "spacingAmount",
                                label: getI18nLocaleObject(namespaceList.admin, "spacingAmount"),
                                type: "number",
                                groupName: "spacingGroup",
                                visible: (options: WidgetOptions) => options.useSpecificUnits,
                            },
                            {
                                variable: "spacingToLeft",
                                label: getI18nLocaleObject(namespaceList.admin, "spacingToLeft"),
                                type: "number",
                                groupName: "spacingGroup",
                            },
                            {
                                variable: "spacingToRight",
                                label: getI18nLocaleObject(namespaceList.admin, "spacingToRight"),
                                type: "number",
                                groupName: "spacingGroup",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetSpacer, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};

export function spacerWidget(type: WidgetType) {
    const spacer: WidgetSpec<WidgetOptions> = {
        id: "spacer",
        type,
        widgetGroup: WidgetGroup.LAYOUT,
        name: getI18nLocaleObject(namespaceList.widgetSpacer, "spacerWidget"),
        description: getI18nLocaleObject(namespaceList.widgetSpacer, "spacerWidgetDescription"),
        optionsForm: widgetOptionsForm,
        defaultOptions: (): WidgetOptions => ({
            styleIds: [],
            spacing: "dk-pt-s",
            useSpecificUnits: false,
            spacingAmount: 0,
            spacingToLeft: 0,
            spacingToRight: 0,
        }),
        container: false,
    };
    (spacer as PageWidgetSpec<WidgetOptions>).render = async (widget: Widget<WidgetOptions>, context: CMSProvidedProperties) => {
        const { styleIds } = widget.options;
        const spaceControl = `${widget.options.spacing ? widget.options.spacing : ""}`;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);

        return <Spacer widgetOptionsForm={widgetOptionsForm} className={className} spaceControl={spaceControl} options={widget.options} widgetId={widget._id} context={context} />;
    };
    return spacer;
}
